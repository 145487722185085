import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import breakpoints from './Theme/breakpoints';

const PageCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 1024px;
  padding: 0 1rem;
  grid-gap: 1.25rem;
  margin: 2.5rem auto 0;

  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;
  }
`;

const pageCardContainerPropertyTypes = {
  pageCards: PropTypes.arrayOf(
    PropTypes.node.isRequired,
  ).isRequired,
};

type PageCardProperties = InferProps<typeof pageCardContainerPropertyTypes>;

const PageCardContainer = ({
  pageCards,
}: PageCardProperties) => (
  <PageCardWrapper>
    { pageCards }
  </PageCardWrapper>
);

PageCardContainer.propTypes = pageCardContainerPropertyTypes;

export default PageCardContainer;
