import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import Body1 from '../Typography/Body/Body1';
import Headline3 from '../Typography/Heading/Headline3';
import { Container } from '../TextWithImage';

const TextContainer = styled.div`
  position: relative;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    order: 1;
    flex-basis: 60%;
    padding: '0 2rem 0 0';
  }

  .MuiTypography-root {
    margin-bottom: 1rem;
  }
`;

const CardContainer = styled.div`
  position: relative;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    order: 12;
    flex-basis: 40%;
    margin-top: 0;
  }
`;

interface TextWithCardProperties {
  title: string;
  description: ReactNode | string;
  card?: ReactNode;
  callToAction: ReactNode;
}

const TextWithCard = ({
  title,
  description,
  card,
  callToAction,
}: TextWithCardProperties) => (
  <Container>
    <TextContainer>
      <Headline3 tag="h2">{title}</Headline3>
      <Body1 tag="div">{description}</Body1>
      {callToAction}
    </TextContainer>
    <CardContainer>
      {card}
    </CardContainer>
  </Container>
);

export default TextWithCard;
