import React from 'react';
import styled from '@emotion/styled';
import Body1 from '../Typography/Body/Body1';

const SegmentWrapper = styled.div`
  width: 100px;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 2rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  transition: .1s ease-in;

  :hover {
    transform: scale(1.1);
  }
`;

const Title = styled(Body1)`
  margin-top: 1rem;

  strong {
    font-weight: bold;
  }
`;

interface SegmentProperties {
  icon: React.ReactNode;
  title: string;
}

const Segment = ({ icon, title }: SegmentProperties) => (
  <SegmentWrapper>
    <IconWrapper>{icon}</IconWrapper>
    <Title><strong>{title}</strong></Title>
  </SegmentWrapper>
);

export default Segment;
