import React from 'react';

const MenswearIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="48px" height="48px">
    <path fill="black" d="M15 13L13 3 17 3z" />
    {/* eslint-disable-next-line max-len */}
    <path fill="black" d="M27.392,12.031L23,5c0,0-3-2-4-2l1,3l-2.5,1L19,9l-4,6l-4-6l1.5-2L10,6l1-3c-1,0-4,2-4,2l-4.392,7.031C2.211,12.666,2,13.401,2,14.15v8.35C2,22.776,2.224,23,2.5,23h3C5.776,23,6,22.776,6,22.5V15l2-3v14c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1v0c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v0c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V12l2,3v7.5c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-8.35C28,13.401,27.789,12.666,27.392,12.031z M15,23c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C16,22.552,15.552,23,15,23z M15,19c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C16,18.552,15.552,19,15,19z" />
  </svg>
);

export default MenswearIcon;
