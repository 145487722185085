import React from 'react';
import Body1 from '@fuww/library/src/Typography/Body/Body1';
import withQuery from '../../lib/withQuery';
import ArticleList from '../News/Article/List';
import LinkAndAnchor from '../LinkAndAnchor';
import { useSiteContext } from '../SiteContext';
import getArticlesField from '../../lib/queries/getConnectionField';
import getNewsboardFieldVariables
  from '../../lib/queries/getNewsboardFieldVariables';
import {
  articleSearchImageConfigurations,
} from '../../lib/imageConfigurations';
import articlesConnectionQuery
  from '../../lib/queries/news/articlesConnectionQuery.graphql';

interface SeasonalNewsSectionProperties {
  seasonInfo: { title: string; slug: string; keywords: string[] };
}

const ArticleListWithQuery = withQuery(ArticleList);

const SeasonalNewsSection = ({
  seasonInfo,
}: SeasonalNewsSectionProperties) => {
  const { prefix, hasLocalNews, newsboard } = useSiteContext();
  const articlesField = getArticlesField(prefix, hasLocalNews);

  const searchVariables = {
    ...getNewsboardFieldVariables(prefix, hasLocalNews),
    newsboards: [newsboard],
    sort: 'NORMAL',
    notInIds: [],
    offset: 0,
    statuses: ['PUBLISHED', 'ARCHIVED'],
    first: 5,
    imageConfigurations: articleSearchImageConfigurations,
    pageviews: false,
  };

  return (
    <>
      <Body1>
        {seasonInfo.title}
        <LinkAndAnchor route="tag" params={{ slug: seasonInfo.slug }}>
          &nbsp;(see more here)
        </LinkAndAnchor>
      </Body1>
      <ArticleListWithQuery
        query={articlesConnectionQuery}
        variables={{
          ...searchVariables,
          keywords: seasonInfo.keywords,
        }}
        errorMessage="Error loading news articles..."
        field={articlesField}
        shouldLoadMore={false}
      />
    </>
  );
};

export default SeasonalNewsSection;
