import React from 'react';

const ShoesIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="48px" height="48px">
    {/* eslint-disable-next-line max-len */}
    <path fill="black" d="M 12.03125 6.9863281 A 1.0001 1.0001 0 0 0 10.998047 8.0292969 C 10.990499 9.0377148 10.543645 11 6 11 C 2 11 2.9995781 9 0.64257812 9 C 0.64257813 9 7.4014868e-17 11.671 0 14 C 0 16.329 0.64257812 18 0.64257812 18 L 7 18 C 9.0967347 18 10.698421 18.906035 12.029297 21.287109 A 1.0001 1.0001 0 0 0 13 22 L 29 22 A 1.0001 1.0001 0 0 0 29.935547 20.613281 C 29.717286 19.476739 28.605825 15.964821 23 15 C 20.280437 14.532043 18.081136 13.651379 16.339844 12.669922 C 13.857495 10.96158 12.96875 7.7519531 12.96875 7.7519531 A 1.0001 1.0001 0 0 0 12.03125 6.9863281 z M 2 20 A 1.0001 1.0001 0 1 0 2 22 L 8 22 A 1.0001 1.0001 0 1 0 8 20 L 2 20 z" />
  </svg>
);

export default ShoesIcon;
