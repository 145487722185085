import React, { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Grid, GridCell } from '../Grid';
import { BreadcrumbsWrapper } from '../StaticPage';
import { Breadcrumbs } from '../Breadcrumb';
import Headline1 from '../Typography/Heading/Headline1';
import Headline3 from '../Typography/Heading/Headline3';
import breakpoints from '../Theme/breakpoints';

const SegmentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const PageWrapper = styled.div`
  margin: 0 auto 32px;
  padding: 0 16px;
  max-width: 1440px;
`;

const StyledHeadline3 = styled(Headline3)`
  text-align: center;
  margin: 2rem 0;
`;

const Introduction = styled.div`
  text-align: center;
`;

const ParnerSpotlightCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0 1rem;
  grid-gap: 1.25rem;
  margin: 2.5rem auto 0;

  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface MarketplacePageProperties {
  breadcrumbs: ReactNode[] | string[];
  intro: ReactElement;
  title: string;
  segments?: ReactElement[];
  hotTrends?: ReactElement;
  partnerSpotlightCards?: ReactElement[];
  featuredArticles?: ReactElement;
  segmentsHeader: string;
  hotTrendsHeader: string;
  partnerSpotlightHeader: string;
  featuredArticlesHeader: string;
  textWithCard?: ReactElement;
}

const MarketplacePage = ({
  breadcrumbs,
  intro,
  title,
  segments,
  hotTrends,
  partnerSpotlightCards,
  featuredArticles,
  segmentsHeader,
  hotTrendsHeader,
  partnerSpotlightHeader,
  featuredArticlesHeader,
  textWithCard,
}: MarketplacePageProperties) => (
  <PageWrapper>
    <Grid>
      <GridCell padding="0">
        <BreadcrumbsWrapper>
          <Breadcrumbs crumbs={breadcrumbs} />
        </BreadcrumbsWrapper>
        <Introduction>
          <Headline1 tag="h1">{title}</Headline1>
          {intro}
        </Introduction>
        {textWithCard}
        <StyledHeadline3>{segmentsHeader}</StyledHeadline3>
        <SegmentsContainer>
          {segments}
        </SegmentsContainer>
        <StyledHeadline3>{hotTrendsHeader}</StyledHeadline3>
        {hotTrends}
        <StyledHeadline3>{partnerSpotlightHeader}</StyledHeadline3>
        <ParnerSpotlightCardsWrapper>
          {partnerSpotlightCards}
        </ParnerSpotlightCardsWrapper>
        <StyledHeadline3>{featuredArticlesHeader}</StyledHeadline3>
        {featuredArticles}
      </GridCell>
    </Grid>
  </PageWrapper>
);
export default MarketplacePage;
