import React from 'react';

const KidswearIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
    {/* eslint-disable-next-line max-len */}
    <path fill="black" d="M19.634,18.848C19.081,18.293,18,17,18,17V4c0-0.552-0.448-1-1-1H7C6.448,3,6,3.448,6,4v13c0,0-1.081,1.293-1.634,1.848 c-0.656,0.66-1.107,1.475-0.654,2.286C4.02,21.687,4.592,22,5.184,22c0.276,0,0.557-0.067,0.814-0.212l3.364-2.102 C9.896,19.39,10,18.61,10,18v-7c0-1.105,0.895-2,2-2s2,0.895,2,2v7c0,0.61,0.104,1.39,0.638,1.686l3.364,2.102 C18.259,21.933,18.54,22,18.816,22c0.592,0,1.164-0.313,1.472-0.866C20.74,20.323,20.289,19.508,19.634,18.848z" />
  </svg>
);

export default KidswearIcon;
