import React from 'react';

const BrandsIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="48px" height="48px">
    {/* eslint-disable-next-line max-len */}
    <path fill="black" d="M 15 5 C 13.35503 5 12 6.3550302 12 8 C 12 9.2308335 12.741015 10.061817 13.240234 10.644531 C 13.739454 11.227245 14 11.561248 14 12 L 14 13.337891 L 2.4433594 18.230469 A 1.0001 1.0001 0 0 0 1.9746094 18.640625 C 1.418509 19.097978 1 19.72643 1 20.5 C 1 21.869063 2.1309372 23 3.5 23 L 26.5 23 C 27.869063 23 29 21.869063 29 20.5 C 29 19.72643 28.581469 19.098295 28.025391 18.640625 A 1.0001 1.0001 0 0 0 27.556641 18.230469 L 16 13.337891 L 16 12 C 16 10.764752 15.260546 9.9282858 14.759766 9.34375 C 14.258985 8.7592142 14 8.4261665 14 8 C 14 7.4349698 14.43497 7 15 7 C 15.56503 7 16 7.4349698 16 8 A 1.0001 1.0001 0 1 0 18 8 C 18 6.3550302 16.64497 5 15 5 z M 15 15.085938 L 26.025391 19.753906 L 26.183594 18.964844 A 1.0001 1.0001 0 0 0 26.71875 20.056641 C 26.889739 20.141789 27 20.298097 27 20.5 C 27 20.786937 26.786937 21 26.5 21 L 3.5 21 C 3.2130628 21 3 20.786937 3 20.5 C 3 20.298097 3.1106953 20.140428 3.2792969 20.056641 A 1.0001 1.0001 0 0 0 3.8164062 18.972656 L 3.9726562 19.755859 L 15 15.085938 z" />
  </svg>

);

export default BrandsIcon;
