import React, { Fragment, ComponentType } from 'react';
import { FetchMoreQueryOptions } from '@apollo/client';
import { ImageContainer, StyledList } from '@fuww/library/src/Article';
import {
  SearchListItemWrapper,
  SearchResultsItem,
} from '@fuww/library/src/List/NewsList';
import { useIntl } from 'react-intl';
import { capitalize } from 'lodash';
import LinkAndAnchor from '../../LinkAndAnchor';
import getArticlesParameters from '../../../lib/getArticlesParameters';
import ImageWithUrls from '../../ImageWithUrls';
import FormattedDate from '../../FormattedDate';
import getCategoryTitle from '../../../lib/getCategoryTitle';
import { useSiteContext } from '../../SiteContext';
import LoadMore from '../../LoadMore';
import loadMore from '../../../lib/queries/loadMore';
import {
  ArticlesConnectionQuery,
  ArticlesConnectionQueryVariables,
  AuthorByEmailQuery,
  AuthorByEmailQueryVariables,
  LocalNewsArticleConnectionFieldsFragment,
} from '../../../lib/graphql/api/graphql';

interface ArticleListItemPropertyTypes {
  article?:
  NonNullable<
  NonNullable<
  NonNullable<
  ArticlesConnectionQuery['newsArticlesConnection']
  >['edges']
  >[number]
  >['node'] |
  NonNullable<
  NonNullable<
  NonNullable<
  LocalNewsArticleConnectionFieldsFragment
  >['edges']
  >[number]
  >['node'];
  isPressRelease?: boolean;
  isPartnerContent?: boolean;
  variables?: ArticlesConnectionQueryVariables | AuthorByEmailQueryVariables;
  isFirstImage: boolean;
  key?: string;
  showLoader: boolean;
}

export type ArticleListItemType = ComponentType<ArticleListItemPropertyTypes>;

const getSearchListLabels = (labels: string[] | undefined) => {
  if (!labels || labels.length === 0) {
    return '';
  }
  return labels.map((label) => ` | ${capitalize(label)}`);
};

export const SearchListItem = ({
  article,
  isFirstImage,
  showLoader,
}: ArticleListItemPropertyTypes) => {
  const intl = useIntl();
  const { hasLocalNews } = useSiteContext();

  const fetchpriority = isFirstImage ? 'high' : 'low';

  return (
    <SearchListItemWrapper className="fu-list-item-content">
      <LinkAndAnchor
        {...showLoader ? {
          href: '#',
        } : {
          route: 'article',
          params: getArticlesParameters(article),
          useRouter: true,
          // eslint-disable-next-line no-underscore-dangle
          href: (article?.__typename === 'LocalNewsArticle' && article.path)
            || undefined,
        }}
      >
        <SearchResultsItem
          article={article}
          date={<FormattedDate value={article?.insertedAt} />}
          category={
              article?.category && ` - ${
                getCategoryTitle(article.category, hasLocalNews, intl)
              }${getSearchListLabels(article.labels)}`
            }
          isPressRelease={article?.pressRelease}
          isPartnerContent={article?.partnerContent}
          image={article?.imageUrls
            .every((imageUrl) => typeof imageUrl === 'string') && (
            <ImageContainer>
              <ImageWithUrls
                imageUrls={article.imageUrls as string[]}
                alt={article.title}
                width="102px"
                height="auto"
                sizes="100px"
                objectFit="cover"
                srcSet={`${article.imageUrls[1]
                } 102w, ${article.imageUrls[2]
                } 204w, ${article.imageUrls[3]
                } 306w`}
                preload={isFirstImage}
                finalFetchpriority={fetchpriority}
                placeholderFetchpriority={fetchpriority}
              />
            </ImageContainer>
          )}
        />
      </LinkAndAnchor>
    </SearchListItemWrapper>
  );
};

const ArticleList = <
  Q extends ArticlesConnectionQuery | AuthorByEmailQuery,
  V extends ArticlesConnectionQueryVariables | AuthorByEmailQueryVariables,
>({
    ArticleListItem = SearchListItem,
    variables,
    fetchMore,
    field,
    connectionField,
    data,
    loading,
    showLoader,
    shouldLoadMore = true,
  } : {
    ArticleListItem?: ArticleListItemType;
    data?: Q;
    variables: V;
    fetchMore: (variables: FetchMoreQueryOptions<V>) => void;
    loading: boolean;
    showLoader: boolean;
    field: Exclude<keyof ArticlesConnectionQuery, '__typename'> |
    Exclude<keyof NonNullable<AuthorByEmailQuery[
      Exclude<keyof AuthorByEmailQuery, '__typename'>
    ]>, '__typename'>;
    connectionField?: Exclude<keyof AuthorByEmailQuery, '__typename'>;
    shouldLoadMore?: boolean;
  }) => {
  const { edges = [], pageInfo: { hasNextPage } = { hasNextPage: false } } = {
    ...(field === 'newsArticlesConnection' && connectionField
      ? (data as AuthorByEmailQuery | undefined)?.[connectionField]?.[field]
      : (data as ArticlesConnectionQuery | undefined)?.[field]
    ),
  };
  return (
    <>
      <StyledList className="fu-mdc-list">
        {edges.length > 0 ? (edges.map((edge, index) => {
          const { node } = { ...edge };

          return (
            <ArticleListItem
              article={node}
              isPressRelease={node.pressRelease}
              isPartnerContent={node.partnerContent}
              variables={variables}
              isFirstImage={index === 0}
              key={node.id.toString()}
              showLoader={showLoader}
            />
          );
        })) : (showLoader ? (Array.from({ length: 4 }, (_, index) => (
          <Fragment key={`skeleton-${index}`}>
            <ArticleListItem
              showLoader
              key={`skeleton-${index}`}
              isPressRelease={false}
              isPartnerContent={false}
              isFirstImage={false}
            />
          </Fragment>
        ))) : null)}
      </StyledList>
      {shouldLoadMore && (
      <LoadMore
        loading={loading}
        hasNextPage={hasNextPage}
        fetchMore={
            () => loadMore({
              variables,
            })(data, field, fetchMore, connectionField)
          }
      />
      )}
    </>
  );
};

export default ArticleList;
