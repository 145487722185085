import React from 'react';

const WomenswearIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px">
    {/* eslint-disable-next-line max-len */}
    <path fill="black" fillRule="evenodd" d="M8.402,11.403c-0.194-1.13-1.201-6.398-1.201-6.398S9.707,4.235,12,7.709c2.291-3.474,4.81-2.704,4.81-2.704s-0.856,5.051-1.201,6.398C17.06,12.278,18,14.233,18,17.005C18,20.409,18,21,18,21s-1.416,0-6,0s-6,0-6,0s0-1.104,0-3.995C6,14.41,7.079,12.325,8.402,11.403z" clipRule="evenodd" />
    {/* eslint-disable-next-line max-len */}
    <path fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2" d="M15 2L15 6M9 2L9 6" />
  </svg>
);

export default WomenswearIcon;
