import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import Body2 from '../Typography/Body/Body2';
import breakpoints from '../Theme/breakpoints';
import Body1 from '../Typography/Body/Body1';

interface TextCardProperties {
  title: string;
  description: ReactNode;
  link?: ReactNode;
}

const Container = styled.div`
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: .25rem;
  padding: 1rem 2rem;

  @media (min-width: ${breakpoints.md}px) {
    margin: 1rem;
  }
`;

const Title = styled(Body1)`
  font-weight: bold;
`;

const TextCard = ({
  title,
  description,
  link,
}: TextCardProperties) => (
  <Container>
    <Title>{title}</Title>
    <Body2 tag="div">{description}</Body2>
    {link}
  </Container>
);

export default TextCard;
