import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import MarketplaceLayout from '@fuww/library/src/Layouts/MarketplacePage';
import Body1 from '@fuww/library/src/Typography/Body/Body1';
import Button from '@fuww/library/src/Button';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import PageCardContainer from '@fuww/library/src/PageCardContainer';
import TextWithCard from '@fuww/library/src/TextWithCard';
import TextCard from '@fuww/library/src/TextCard';
import SpacingContainer from '@fuww/library/src/SpacingContainer';
import Segment from '@fuww/library/src/Marketplace/Segment';
import ImageContainer from '@fuww/library/src/Marketplace/ImageContainer';
import AdaptiveCard from '@fuww/library/src/Cards/AdaptiveCard';
import LinkAndAnchor from '../components/LinkAndAnchor';
import messages from '../lib/messages.mjs';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Hero from '../components/Hero';
import marketplaceSegments from '../data/marketplace/marketplaceSegments';
import hotTrends from '../data/marketplace/hotTrends.json';
import partnerSpotlight from '../data/marketplace/partnerSpotlight.json';
import {
  companyProfilesCardImageConfigurations,
  marketplaceHotTrendsImageConfigurations,
} from '../lib/imageConfigurations';
import getProxiedImages from '../lib/getProxiedImages';
import ImageWithUrls from '../components/ImageWithUrls';
import HomeLink from '../components/HomeLink';
import SeasonalNews from '../components/Marketplace/SeasonalNews';

const seasonalInfo = {
  current: {
    title: 'Spring / Summer 2025',
    slug: 'ss25',
    keywords: ['ss25'],
  },
  upcoming: {
    title: 'Fall / Winter 2025',
    slug: 'fw25',
    keywords: ['fw25'],
  },
};

const headerImageUrls = {
  mobile: [
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=34,quality=10,width=42/https://media.fashionunited.com/media/marketplace-hero',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=288,quality=70,width=360/https://media.fashionunited.com/media/marketplace-hero',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=576,quality=70,width=720/https://media.fashionunited.com/media/marketplace-hero',
  ],
  desktop: [
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=8,quality=10,width=42/https://media.fashionunited.com/media/marketplace-hero',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=277,quality=70,width=1350/https://media.fashionunited.com/media/marketplace-hero',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=526,quality=70,width=2560/https://media.fashionunited.com/media/marketplace-hero',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=top,height=1052,quality=70,width=5120/https://media.fashionunited.com/media/marketplace-hero',
  ],
};

const partnerSpotlightCards = partnerSpotlight.map(({
  title,
  params,
  cardImageUrls: originalImageUrls,
}) => {
  const { imageUrls, srcSet } = getProxiedImages(
    { imageConfigurations: companyProfilesCardImageConfigurations },
    originalImageUrls,
  );

  return (
    <LinkAndAnchor
      key={title}
      route="products"
      params={params}
    >
      <AdaptiveCard
        title={title}
        textAlign="center"
        image={imageUrls[0] && (
        <ImageWithUrls
          sizes={`
          (min-width: 1440px) 318px,
          (min-width: ${breakpoints.lg}px) calc(25vw - 42px),
          (min-width: ${breakpoints.md}px) calc(50vw - 52px),
          calc(100vw - 72px)
        `}
          srcSet={srcSet}
          imageUrls={imageUrls}
          alt=""
          disableRoundedCorners
        />
        )}
      />
    </LinkAndAnchor>
  );
});

const MarketplacePage = () => {
  const intl = useIntl();

  const breadcrumbs = [
    <HomeLink />,
    'Marketplace',
  ];

  return (
    <>
      <Head
        title={intl.formatMessage(
          messages['marketplace.title'],
        )}
        description={intl.formatMessage(
          messages['marketplace.description'],
        )}
      />
      <MarketplaceLayout
        breadcrumbs={breadcrumbs}
        intro={(
          <>
            <SpacingContainer />
            <Body1>
              {intl.formatMessage(messages['marketplace.description'])}
            </Body1>
            <SpacingContainer />
            <LinkAndAnchor
              route="products"
              params={{ marketId: '1' }}
            >
              <Button variant="contained">
                {intl.formatMessage(messages['marketplace.buttonLabel'])}
              </Button>
            </LinkAndAnchor>
          </>
        )}
        textWithCard={(
          <TextWithCard
            title={intl.formatMessage(
              messages['marketplace.apparelBuyers.title'],
            )}
            description={(
              <div
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    messages['marketplace.apparelBuyers.description'],
                  ),
                }}
              />
            )}
            callToAction={(
              <LinkAndAnchor
                route="register-buyer"
              >
                <Button variant="outlined">
                  {intl.formatMessage(
                    messages['marketplace.apparelBuyers.callToAction'],
                  )}
                </Button>
              </LinkAndAnchor>
              )}
            card={(
              <TextCard
                title={intl.formatMessage(messages['marketplace.card.title'])}
                description={(
                  <div
                // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        messages['marketplace.card.description'],
                      ),
                    }}
                  />
                )}
                link={(
                  <LinkAndAnchor href="/marketplace/brands">
                    <Button variant="outlined">
                      {intl.formatMessage(
                        messages['marketplace.registerButtonText'],
                      )}
                    </Button>
                  </LinkAndAnchor>
                )}
              />
          )}
          />
        )}
        title={intl.formatMessage(
          messages['marketplace.title'],
        )}
        segments={marketplaceSegments.map(({
          title,
          route,
          params,
          icon,
        }) => (
          <LinkAndAnchor key={title} route={route} params={params}>
            <Segment icon={icon} title={title} />
          </LinkAndAnchor>
        ))}
        hotTrends={(
          <PageCardContainer pageCards={hotTrends.map(({
            title,
            params,
            cardImageUrls: originalCardImageUrls,
          }) => {
            const { imageUrls: cardImageUrls, srcSet } = getProxiedImages(
              { imageConfigurations: marketplaceHotTrendsImageConfigurations },
              originalCardImageUrls,
            );

            return (
              <ImageContainer key={title}>
                <LinkAndAnchor
                  route="products"
                  params={params}
                >
                  <ImageWithUrls
                    sizes={`(
                      min-width: ${breakpoints.md}px) calc((100vw - 160px) / 3
                    ),
                      (min-width: ${breakpoints.sm}px) calc((100vw - 84px) / 2),
                      calc(100vw - 64px)
                    `}
                    srcSet={srcSet}
                    imageUrls={cardImageUrls}
                    alt={title}
                    disableRoundedCorners
                  />
                </LinkAndAnchor>
                <Body1><strong>{title}</strong></Body1>
              </ImageContainer>
            );
          })}
          />
        )}
        partnerSpotlightCards={partnerSpotlightCards}
        featuredArticles={(
          <>
            <SeasonalNews seasonInfo={seasonalInfo.current} />
            <SpacingContainer />
            <SeasonalNews seasonInfo={seasonalInfo.upcoming} />
          </>
        )}
        segmentsHeader={intl.formatMessage(
          messages['marketplace.segmentsHeader'],
        )}
        hotTrendsHeader={intl.formatMessage(
          messages['marketplace.hotTrendsHeader'],
        )}
        partnerSpotlightHeader={intl.formatMessage(
          messages['marketplace.partnerSpotlightHeader'],
        )}
        featuredArticlesHeader={intl.formatMessage(
          messages['marketplace.featuredArticlesHeader'],
        )}
      />
    </>
  );
};

MarketplacePage.getLayout = (page: ReactElement) => (
  <Layout
    header={(
      <Hero
        alt="Marketplace banner image"
        imageUrls={headerImageUrls}
      />
    )}
  >
    {page}
  </Layout>
);

export default MarketplacePage;
