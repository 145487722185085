import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import Body1 from '../Typography/Body/Body1';
import Headline3 from '../Typography/Heading/Headline3';

interface TextWithImageProperties {
  imagePosition?: 'left' | 'right';
  title: string;
  description: ReactNode | string;
  image?: ReactNode;
  button?: ReactNode;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 4rem auto;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}px) {
    margin: 4rem 0 0;
    flex-direction: row;
  }
`;

const TextContainer = styled.div<{ imagePosition?: string }>`
  position: relative;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    order: ${(properties) => (properties.imagePosition === 'right' ? 1 : 12)};
    flex-basis: 66.66667%;
    padding: ${(properties) => (properties.imagePosition === 'right'
    ? '0 2rem 0 0'
    : '0 0 0 2rem'
  )};
  }

  .MuiTypography-root {
    margin-bottom: 1rem;
  }
`;

const ImageContainer = styled.div<{ imagePosition?: string }>`
  position: relative;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    order: ${(properties) => (properties.imagePosition === 'right' ? 12 : 1)};
    flex-basis: 33.33333%;
    margin-top: 0;
  }
`;

const Button = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.md}px) {
    text-align: left;
  }
`;

const TextWithImage = ({
  imagePosition = 'left',
  title,
  description,
  image,
  button,
} : TextWithImageProperties) => (
  <Container>
    <TextContainer imagePosition={imagePosition}>
      <Headline3 tag="h2">{title}</Headline3>
      <Body1 tag="div">{description}</Body1>
      <Button>{ button }</Button>
    </TextContainer>
    <ImageContainer imagePosition={imagePosition} aria-hidden="true">
      {image}
    </ImageContainer>
  </Container>
);

export default TextWithImage;
