import React from 'react';

const AccessoriesIcon = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="48px" height="48px">
    {/* eslint-disable-next-line max-len */}
    <path fill="black" d="M 3.984375 3.9863281 A 1.0001 1.0001 0 0 0 3 5 C 3 12.978923 7.7530419 19.321267 14 19.939453 L 14 21.333984 L 12 24 L 15 28 L 18 24 L 16 21.333984 L 16 19.939453 C 22.246958 19.321267 27 12.978923 27 5 A 1.0001 1.0001 0 1 0 25 5 C 25 12.108132 20.966576 17.317498 15.998047 17.9375 A 1.0001 1.0001 0 0 0 14.984375 16.986328 A 1.0001 1.0001 0 0 0 14.001953 17.9375 C 9.0334238 17.317498 5 12.108132 5 5 A 1.0001 1.0001 0 0 0 3.984375 3.9863281 z" />
  </svg>
);

export default AccessoriesIcon;
