import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { mediumRoundedCorners } from '../RoundedCorners';

interface ImageContainerProperties {
  children: ReactNode;
}

const Container = styled.div`
  transition: transform 0.2s ease-in-out;

  div { 
    ${mediumRoundedCorners};
  }

  :hover {
    transform: scale(1.02);
  }
`;

const ImageContainer = ({ children }: ImageContainerProperties) => (
  <Container>
    { children }
  </Container>
);

export default ImageContainer;
